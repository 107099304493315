import * as React from 'react'

import { EnhancedOnboarding as EnhancedOnboardingPage } from 'shared/pages'

import Layout from '../layouts/onboarding'

const EnhancedOnboarding = () => (
  <Layout>
    <EnhancedOnboardingPage />
  </Layout>
)

export default EnhancedOnboarding
